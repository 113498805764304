import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

 function Header({ siteTitle }) {
  return (
    <div>
      <header className="flex flex-wrap items-center justify-center">
        <h1 className="py-4 uppercase font-serif text-5xl">Vasusen</h1>
      </header>
      <nav className="flex flex-wrap items-center justify-center p-4 mb-6 border-t-2 border-b-4 border-gray-600">

        <div className="block items-center w-auto">
          <span className="text-xl font-serif font-semibold uppercase">
            <Link
              to="/"
              className="header-link"
            >
              About
            </Link>
          </span>

          <span className="text-xl font-serif font-semibold uppercase">
            <Link
              to="/projects"
              className="header-link"
            >
              Projects
            </Link>
          </span>

          <span className="text-xl font-serif font-semibold uppercase">
            <Link
              to="/contact"
              className="header-link"
            >
              Contact
            </Link>
          </span>
        </div>
      </nav>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
