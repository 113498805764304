/** A footer component for this personal site */

import { Link } from "gatsby"
import React, { useState } from "react"

function Footer({ developerName }) {
    return (
        <footer className="text-xs font-serif flex flex-wrap items-center justify-center p-6 mt-6 border-t-4 border-gray-600">
            <p><strong><Link to="/contact/" className="hover:underline">Contact</Link></strong> | Copyright © {new Date().getFullYear()} by {developerName}</p>
        </footer>
    )
}

export default Footer