/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="container" className="mx-auto max-w-2xl justify-center items-center dark:text-gray-400 dark:bg-gray-800">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        className="font-sans mx-4 lg:mx-0 pb-4 min-h-[80%] mb-6"
      >
        <main>{children}</main>
      </div>
      <Footer developerName={`Vasusen`} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
